import axios from "axios";
import Cookies from "js-cookie";

const ENDPOINT_PATH = process.env.VUE_APP_API;

export default {
  setUserLogged(userLogged) {
    Cookies.set("userLogged", userLogged, { expires: 365 });
  },
  getUserLogged() {
    return Cookies.get("userLogged");
  },
  login(email, password) {
    //const user = { email, password };
    return axios.post(
      ENDPOINT_PATH + "/api/login?" + "email=" + email + "&password=" + password
    );
  },
  logout() {
    Cookies.remove("userLogged");
  },
  refresh(id) {
    const body = { id };
    return axios.post(ENDPOINT_PATH + "/api/refresh", body);
  },
};
