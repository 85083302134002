import Vue from "vue";
import "./plugins/vuetify";
import App from "./App.vue";
import { router } from "./router";
import store from "./store";
//import Vuetify from './plugins/vuetify'
import axios from "axios";
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@fortawesome/fontawesome-free/css/all.css";
import PrevisionHub from "./plugins/prevission-hub";
import LogbookHub from "./plugins/logbook-hub";
import VueSVGIcon from "vue-svgicon";
import moment from "vue-moment";
import authService from "@/services/authService";


Vue.use(VueSVGIcon);
Vue.config.productionTip = false;
Vue.use(moment);

// Setup axios as the Vue default $http library
//axios.defaults.baseURL = 'http://cblaspalmasbackend.amurapilot.com/'
//Vue.prototype.$axios = axios
//Vue.prototype.$http = axios

//Vue.use(Vuetify, { theme })
// Install Vue extensions
//Vue.use(BootstrapVue);
Vue.use(PrevisionHub);
Vue.use(LogbookHub);

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function(error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function(resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      } else {
        isRefreshing = true;
        originalRequest._retry = true;
        if (authService.getUserLogged()) {
          await authService
            .refresh(JSON.parse(authService.getUserLogged()).remember_token)
            .then((response) => {
              isRefreshing = false;
              if (response.data.success.token) {
                let user = JSON.parse(authService.getUserLogged());
                user.token = response.data.success.token;
                authService.setUserLogged(user);

                originalRequest.headers["Authorization"] =
                  "Bearer " + user.token;
                processQueue(null, user.token);
                return axios(originalRequest);
              }
            })
            .catch((error) => {
              authService.logout();
              router.replace("/login");
              return Promise.reject(error);
            });
        } else {
          return Promise.reject(error);
        }
      }
    } else if (originalRequest._retry || error.response.status === 403) {
      authService.logout();
      router.replace("/login");
      return Promise.reject(error);
    }
  }
);

new Vue({
  router,
  store,
  render: (h) => h(App),
  methods: {
    moment: function(date) {
      return moment(date);
    },
  },
}).$mount("#app");
