<template>
<div>
  <!-- <VesselCard :name='Hola' ></VesselCard> -->
    <v-data-table
      :headers ="this.headers"
      :items ="this.elemnents"
      :loading="false"
      class="controlboard_table"
      hide-actions
      :expand="expand"
      v-bind:item-key="this.itemKey"
    >
      <template slot="items" slot-scope="props" class="p-10">
        <tr @click="props.expanded = !props.expanded" :class="setRowClass(props, classElement)">

          <td v-bind:key="header.value" v-for="header in headers">
            <Formatter v-if="header.type!==undefined" :value="props.item[header.value]"
             :fn="header.type" :format="header.format" /> 
            <span  v-else> {{props.item[header.value]}} </span>
            
          </td>
        </tr>
      </template>
      <!-- <template v-slot:expand="props" >
          <VesselCard v-bind:name="props.item['nombreBuque']" v-bind:elements="props.item"> </VesselCard>
      </template> -->
      <template v-if="expandComponent != ''" v-slot:expand="props"  >
        <component v-bind:is='expandComponent' v-bind:element="props.item"/>
      </template>
    </v-data-table>
    </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import Formatter from '@/components/Formatter.vue'
import VesselCard from '@/components/VesselCard.vue'
function  parser(value)
{
  console.log(value);
  return value;
}

 export default {
   components:
   {
     Formatter,
     VesselCard,
   },
   props: {
     title: {type: String}, 
     elemnents: Array,
     headers: Array,
     classElement : String,
     expandComponent: String,
     itemKey: String
   },
   data () {
    return {
      expand: false,
      currentView: "vesselCard"
    }
  },
  methods:{
    setRowClass(props, classElement){
      if(classElement === undefined)
      return "";
      return props.item[classElement];
    }
  }
 }
</script>
<style  lang="scss">

.controlboard_table
  
{ 


.theme--light.v-table tbody tr {
background-color: var(--v-primary-base);
color: var(--v-fontbase-base);
border: 1px solid var(--v-tertiary-base);

}

.theme--light.v-table tbody tr:hover
{
  background-color: var(--v-accent-base);
  color: var(--v-fontalt-base);
}

 th{
  background-color: var(--v-secondary-base);
  color: var(--v-fontbase-base) ;
}

 .theme--light.v-datatable thead th.column.sortable.active,
 .theme--light.v-datatable thead th.column.sortable,
 .theme--light.v-datatable thead th.column
{
  background-color: var(--v-secondary-base) ;
  color: var(--v-fontbase-base);
}

 .theme--light.v-datatable thead th.column.sortable .v-icon,
 .theme--light.v-datatable thead th.column.sortable .v-icon:hover
{
  color: var(--v-fontbase-base);
}

 .theme--light.v-datatable .v-datatable__actions
{
  background-color: var(--v-secondary-base);
  color: var(--v-fontbase-base);
}

 .theme--light.v-select .v-select__selections
{
  color: var(--v-fontbase-base);
}

 .v-table__overflow
{
  padding: 1px;
}

}
</style>