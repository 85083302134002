import Vue from "vue";
import Vuetify from "vuetify";
import colors from "vuetify/es5/util/colors";
import "vuetify/src/stylus/app.styl";

Vue.use(Vuetify, {
  theme: {
    primary: {
      base: "#293a5e",
      dark: colors.purple.darken1,
      darken1: colors.purple.darken2,
    },
    secondary: {
      base: "#30446b",
    },
    accent: {
      base: "#fba83e",
    },
    //TODO: warning
    //TODO: info
    //TODO: success
    // Custom theme elements
    tertiary: "#415e90",
    head: {
      base: "#2d2c37",
    },
    fontbase: "#fff",
    fontalt: "#000",
    darkCard: "#1a253a",
    error: "#e3494b",
  },
  options: {
    customProperties: true,
  },
});

export default {};
