import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Previsiones from "./views/Previsiones.vue";
import Mapa from "./views/Mapa.vue";
import BuquesPuerto from "./views/BuquesPuerto.vue";
import Salidas from "./views/Salidas.vue";
import Entradas from "./views/Entradas.vue";
import Login from "./views/Login.vue";

import authService from "@/services/authService";

Vue.use(Router);

let checkLogin = (to, from, next) => {
  if (authService.getUserLogged()) {
    next();
  } else {
    router.replace("/login");
  }
};

export const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/previsiones",
      name: "previsiones",
      component: Previsiones,
      beforeEnter: checkLogin,
    },
    {
      path: "/salidas",
      name: "salidas",
      component: Salidas,
      beforeEnter: checkLogin,
    },
    {
      path: "/entradas",
      name: "entradas",
      component: Entradas,
      beforeEnter: checkLogin,
    },
    {
      path: "/buquespuerto",
      name: "buquespuerto",
      component: BuquesPuerto,
      beforeEnter: checkLogin,
    },
    {
      path: "/",
      name: "home",
      component: Home,
      beforeEnter: checkLogin,
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/mapa",
      name: "mapa",
      component: Mapa,
      beforeEnter: checkLogin,
    },
    { path: "*", redirect: "/" },
    ,
    { path: "**", redirect: "/" },
  ],
});
