<template>

<div>
    <div class="d-flex flex-column">
      <Simple v-if="renderComponent"/>
      <!--<div class="p-0"><Simple v-if="renderComponent"/></div>
        <div class="p-0">
          <div class="d-flex flex-row">
            <div class="p-0"><Simple2 v-if="renderComponent2"/></div>
            <div class="p-0"><Simple3 v-if="renderComponent3"/></div>
          </div>  
      </div>-->
    </div>
  </div>  
<!--<div class="d-flex flex-column mb-6" > 
  
    <v-card class="" outlined tile><Simple/></v-card>
    <v-card class="" outlined tile>
      <div class="d-flex flex-row mb-6" outlined tile>
        <v-card class="" outlined tile><Simple2/></v-card>
        <v-card class="" outlined tile><Simple3/></v-card>
      </div>    
    </v-card>
</div>-->  
</template>

<script>
import Simple from "@/components/Simple";
import Simple2 from "@/components/Simple2";
import Simple3 from "@/components/Simple3";


export default {
  name: "mapa",
    components: {
    Simple,
    Simple2,
    Simple3
    
  },
  data() {
    return {
        renderComponent: true,
        polling: null,
        renderComponent2: true,
        polling2: null,
        renderComponent3: true,
        polling3: null,

    }
  },
  mounted (){},
  methods: {
    forceRerender () {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    forceRerender2 () {
      this.renderComponent2 = false;
      this.$nextTick(() => {
        this.renderComponent2 = true;
      });
    },
  forceRerender3 () {
      this.renderComponent3 = false;
      this.$nextTick(() => {
        this.renderComponent3 = true;
      });
  },

    
  refresh(){
    this.polling = setInterval(() => {
      this.forceRerender();
    }, 1000);  
  },
},

  beforeDestroy () {},
  created () {}

}

</script>
