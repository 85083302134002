<template>
<div id="carrucel" class="primary">
    <div class="row">   
        <div class="col-1 p-0 text-right"><!--<v-icon id="incident_icon" @click="stopAlert"  size="50" color="accent" >warning</v-icon>--></div>
        <div class="col-11 p-0">
        <div class="offset-1"></div> 
        <div id="animacion">
            <p v-for="incidencia in incidencias" :key="incidencia.logbookid" class="ani textoObservaciones">
                <span class="txtFechaHora">{{incidencia.fecha  | moment("DD/MM/YYYY")}} {{incidencia.hora | moment("hh:mm:ss")}}</span> | {{incidencia.tipoIncidencia }} | {{incidencia.buqueNombre }} | {{incidencia.observaciones }} <span class="separador">||</span>         
            </p>
        </div>
              
        
       
       
            <!--carousel 
            <v-carousel ref="prueba" hide-delimiters height="auto" :cycle="true" interval="10000" class="textoIncidencias">
                <v-carousel-item v-for="incidencia in incidencias" :key="incidencia.logbookid" height="auto" class="textoObservaciones" >  
                    {{incidencia.fecha  | moment("DD/MM/YYYY")}} {{incidencia.hora | moment("hh:mm:ss")}} | {{incidencia.tipoIncidencia }} | {{incidencia.buqueNombre }} | {{incidencia.observaciones }}    
                    <div class="d-flex flex-row flex-nowrap  align-content-center align-items-center col-12 flexCustom">
                      <div class="col-5 p-4 textoObservaciones"></div>
                      <div class="col-1 p-4"></div>
                      <div class="col-1 p-4"></div>
                      <div class="col-1 p-4"></div>
                    </div>
                </v-carousel-item>
            </v-carousel>  -->

        </div>
    </div>
    
</div>    
</template>

<script>
export default
{
    created(){
        this.$http.get('/api/logbook').then(res => {
        this.incidencias = res.data
    })
    this.$logbookHub.$on('logbook-added', this.onLogbookAdded)
    this.$logbookHub.$on('logbook-updated', this.onLogbookUpdated)
    this.$logbookHub.$on('logbook-deleted', this.onLogbookDeleted)
    this.$logbookHub.$on('reload-data', this.onReloadData)
    },
    
    data(){
        return {
            incidencias : []
        }
    },
    methods:{
        onLogbookAdded(logbook)
        {
            this.$data.incidencias.unshift(logbook);
            this.animateAlert();
            this.carouselGoTo(0);
        },
        carouselGoTo(index){
            let carousel = this.$refs.prueba;
            var item = carousel.items[index];
            carousel.internalValue = carousel.getValue(item, index);
        },
        onLogbookUpdated(prevision)
        {
            this.$data.incidencias = this.$data.incidencias.filter( function(list_item) {
                return list_item.logbookId != prevision.logbookId;
            })  
            this.$data.incidencias.push(prevision);
            this.animateAlert();
            this.carouselGoTo(this.$data.incidencias.length-1);
        },
        onLogbookDeleted(id)
        {
            this.$data.incidencias = this.$data.incidencias.filter( function(list_item) {
                return list_item.logbookId != id;
            })
        },
        onReloadData(previsions)
        {
            this.$data.incidencias = previsions
        },
        animateAlert(){
            let icon = document.getElementById("incident_icon")
            if(icon !== undefined)
            {
                icon.classList.add("animated")
                icon.classList.add("flash")
            }
        },
        stopAlert()
        {
            let icon = document.getElementById("incident_icon")
            if(icon !== undefined)
            {
                icon.classList.remove("animated")
                icon.classList.remove("flash")
            }
        },
        
         
        

    }
    
}
</script>

<style scoped>
.animated {
 -webkit-animation-duration: 3s;
 animation-duration: 3s;
 animation-iteration-count: infinite;
 -webkit-animation-fill-mode: both;
 animation-fill-mode: both;
}
@-webkit-keyframes flash {
 0%, 50%, 100% {
 opacity: 1;
 }
25%, 75% {
 opacity: 0;
 }
}
@keyframes flash {
 0%, 50%, 100% {
 opacity: 1;
 }
25%, 75% {
 opacity: 0;
 }
}
.flash {
 -webkit-animation-name: flash;
 animation-name: flash;
}

v-carousel{
    width:100%;
    
}
.stretch_text
{
   /* width: 100%;
    /* margin: 0 auto; */
    padding-left: 6%;
    /* white-space: nowrap; */
    /* text-overflow: ellipsis; */
    /* overflow: hidden; */
    

}
#animacion {
  animation-duration: 600s;
  animation-name: slidein;
  animation-iteration-count: infinite;
  width: max-content;
   animation-timing-function: linear;

}


@keyframes slidein {
  from {
    margin-left: 100%;
    
  }

  to {
    margin-left: -4000%;
    
  }
}

p {
    margin-top: 0;
    margin-bottom: 0rem;
    float: left;
    color: white;
    font-size: 26pt;
    
}
</style>
<style lang="scss">
.txtFechaHora{
    font-weight: bolder;
}
.v-carousel{
    box-shadow: none !important;
    position: inherit;
    position: inherit;
    margin-left: -38px;
    margin-top: 13px;
    text-align: center;
}

.v-carousel__prev {
    left: 3% !important;
}
.container.fluid {
    max-width: 100%;
    height: 216px;
    padding: 7px;
}
.card{
    height: 179px;
}

.ajustar {
clear: both;
}

.v-window__container{
    height: auto !important;
}

.v-responsive .v-image .v-carousel__item{
    height: auto !important;
}

.flexCustom{
    color:white;
    font-size: 1.3vh;
}

.textoIncidencias{
    color: white;
    font-size: 1.9rem;
}

.v-carousel__prev, .v-carousel__next{
    display: none;
}

.separador{
    margin-left: 28px;
    margin-right: 28px;
}
</style>