<template>
  <div class="homeContainer">
    <HomeComponent msg="ControlBoard App" />
  </div>
</template>
<script>
import HomeComponent from "@/components/HomeComponent";

export default {
  name: "home",
  components: {
    HomeComponent,
  },

  mounted() {},
  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped>
.homeContainer {
  background-image: url("../assets/portada_amura_horizontal.jpg");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}
@media (max-aspect-ratio: 1/1) {
  .homeContainer {
    background-image: url("../assets/portada_amura.jpg");
  }
}
</style>
