















import { Component, Vue } from 'vue-property-decorator';
 import DataTable from '@/components/DataTable.vue'; // @ is an alias to /src
 import * as jsonData from '../assets/data'
 

@Component({
  components: {
    DataTable
  },
  created(){
    this.$http.get('/api/prevision').then(res => {
      
      res.data.forEach(filtro);
      function filtro(item,index) {
          if(item.sentidoCodigo === 'S' ){
            res.data.splice(index, 1);
          }else if(item.sentidoCodigo === 'M' ){
            res.data.splice(index, 1);
          }
      }
      this.dataNumbers = res.data;
    })
    this.$previssionHub.$on('prevision-added', this.onPrevisionAdded)
    this.$previssionHub.$on('prevision-updated', this.onPrevisionUpdated)
    this.$previssionHub.$on('prevision-deleted', this.onPrevisionDeleted)
    this.$previssionHub.$on('reload-data', this.onReloadData)
  },
  data () {
     return {
       dataNumbers : [],
       headers: [
       { text: 'Man.', value:'sentidoCodigo', sortable: true, classAttribute:'sentidoCodigo'},
      //  { text: 'IMO', value: 'imo', sortable: true },
       { text: 'Buque', value: 'buqueNombre', sortable: true, classAttribute:'sentidoCodigo'},
       { text: 'ETA', align: 'left', value: 'eta', sortable: true, type: "date", classAttribute:'sentidoCodigo' },
       { text: 'Hora', value: 'etah', sortable: true, type:"time", classAttribute:'sentidoCodigo' },
       { text: 'GT', value: 'gt', sortable: true, classAttribute:'sentidoCodigo' },
       { text: 'Consignatario', value: 'consignatarioNombre', sortable: true, classAttribute:'sentidoCodigo' },
       { text: 'Atracado', value: 'atraqueActual', sortable: true, classAttribute:'sentidoCodigo' },
       { text: 'Destino', value: 'destino', sortable: true, classAttribute:'sentidoCodigo' },
       { text: 'Practico', value: 'nombrePractico', sortable: true, classAttribute:'sentidoCodigo' },
       //{ text: 'Origen', value: 'origen', sortable: true },
       //TODO: COSTADO, , FECHAHORALLAMADA, AAACTUAL
       { text: 'Observaciones', value: 'observacion', sortable: true, classAttribute:'sentidoCodigo' },
       ],
       classElement: "sentidoCodigo"
       //rowsPerPageItems: [7, 14, 21, {'text': '$vuetify.dataIterator.rowsPerPageAll', 'value': -1}]
     }
   },
    computed: {
    //  dataNumbers: {
    //    get () {
    //      var dataObjects = jsonData["data1"]
    //      return dataObjects
    //    }
    //  }
   },
   methods: {
     onPrevisionAdded(prevision)
     {
       //alert(JSON.stringify(prevision));
       //this.dataNumbers.push(prevision);  
       this.$data.dataNumbers.push(prevision);

     },
     onPrevisionUpdated(prevision)
     {
       //alert(JSON.stringify(prevision));
       //this.dataNumbers.push(prevision);
       
        this.$data.dataNumbers = this.$data.dataNumbers.filter( function(list_item) {
          return list_item.previsionId != prevision.previsionId;
        })  
       this.$data.dataNumbers.push(prevision);

     },
     onPrevisionDeleted(id)
     {
       this.$data.dataNumbers = this.$data.dataNumbers.filter( function(list_item) {
        return list_item.previsionId != id;
        })
     },
     onReloadData(previsions)
     {
       this.$data.dataNumbers = previsions
     }
   }
})



export default class CodeExamples extends Vue {
  jsonData
}
