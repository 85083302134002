import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr'
import Axios from 'axios';
export default {
    install (Vue) {
      const connection = new HubConnectionBuilder()
        //.withUrl('http://192.168.1.11:45455//question-hub')
        .withUrl(Axios.defaults.baseURL + '///prevision-hub')
        .configureLogging(LogLevel.Information)
        .build()
  
      const previssionHub = new Vue() // use new Vue instance as an event bus
  
      connection.on('Added', prevision => {
        previssionHub.$emit('prevision-added', prevision)
      })
      connection.on('Updated', prevision => {
        previssionHub.$emit('prevision-updated', prevision)
      })
      connection.on('Deleted', id =>{
        previssionHub.$emit('prevision-deleted', id)
      })
      connection.on('Data', previsions => {
        previssionHub.$emit('reload-data', previsions)
      })
      // Add the hub to the Vue prototype, so every component can listen to events or send new events using this.$questionHub
      Vue.prototype.$previssionHub = previssionHub
      
      // You need to call connection.start() to establish the connection
      // the client wont handle reconnecting for you! Docs recommend listening onclose
      // and handling it there. This is the simplest of the strategies
      let startedPromise = null
      function start () {
        startedPromise = connection.start()
          .catch(err => {
            console.error('Failed to connect with prevision hub', err)
            return new Promise((resolve, reject) => setTimeout(() => start().then(resolve).catch(reject), 5000))
          })
        return startedPromise
      }
      connection.onclose(() => start())
  
      //start()
    }
  }
  