<template >
    <v-card  class="pa-3" flat color="darkCard">
        <v-layout justify-space-around row>
        <v-flex grow md5 pa-0>
        <v-img
          :src='element["Photo"]'
          max-height="180"
          @error="imageNotFound"
          contain
          alt="Imagen no encontrada"
        ></v-img>
        </v-flex>
        <v-flex md5>
        <v-card-title primary-title class="white--text">
          <div>
            <h4>IMO: {{element["Imo"]}}  </h4> 
            <h4>MMSI: {{ element["Mmsi"]}} </h4>
            <h4>Distintivo de llamada: {{element["CallSign"]}} </h4>
            <h4>Arqueo bruto: {{element["Gt"]}} </h4>
            <h4>Eslora Total x Manga Máxima: {{element["Length"]}}m x {{element["Beam"]}}m </h4>
            <h4>Año de construcción: {{element["BuildingDate"]}} </h4>
            <h4>Bandera: {{element["Flag"]}}m </h4>
          </div>
        </v-card-title>
        </v-flex>
        </v-layout>
    </v-card>
</template>

<script>

export default
{
    props:{
        name: String,
        element: Object
    },
    computed:{
        url: function() {
            return "http://vessels.amurapilot.com/uploads/imagenesbuques/" +
            this.element["Imo"]
            + ".jpg";
        }
    },
    methods:{
        imageNotFound:function(){
            console.log("image not found");
        }
    }
}
</script>