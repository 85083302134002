<template>
  <div>
    <div class="d-flex flex-column">
      <listasalidas />
    </div>
  </div>
</template>

<script>
import listasalidas from "@/components/listasalidas";

export default {
  name: "Salidas",

  components: {
    listasalidas,
  },
};
</script>

<style lang="scss" scoped>
.titlePageS {
  width: 100%;
  height: 53px;
  background-color: rgb(180, 62, 63) !important;
  color: white;
  text-align: center;
  padding: 11px;
  font-size: 25px;
}

.v-card {
  width: 100%;
  height: auto;
  background-color: #293a5e !important;
}
.theme--light.v-btn:not(.v-btn--icon):not(.v-btn--flat) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.theme--light.v-table tbody tr.E {
  background-color: #345257;
}
.theme--light.v-table tbody tr.S {
  background-color: #4e384c;
}

.v-card {
  background-color: #293a5e !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>
