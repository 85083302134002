<template>
  <div>
    <div class="d-flex flex-column">
      <listaentradas />
    </div>
  </div>
</template>

<script>
import listaentradas from "@/components/listaentradas";

export default {
  name: "previsiones",

  components: {
    listaentradas,
  },
};
</script>

<style lang="scss" scoped>
.titlePage {
  width: 100%;
  height: 53px;
  background-color: #01ca6e;
  color: white;
  text-align: center;
  padding: 11px;
  font-size: 25px;
}
.theme--light.v-table tbody tr.E {
  background-color: #345257;
}
.theme--light.v-table tbody tr.S {
  background-color: #4e384c;
}
.v-content__wrap {
  background-color: #293a5e;
}

html {
  background-color: #293a5e;
}
</style>
