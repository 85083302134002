<template>
  <div id="full_div3" style="width: 100%; height: 20vh;" ></div>
</template>

<script>
import postscribe from 'postscribe'

export default {
  name: "Example",
  mounted: function () {
    postscribe('#full_div3', `<script> 
      var width="100%";         // width in pixels or percentage
      var height="450";         // height in pixels
      var latitude="27.843547";     // center latitude (decimal degrees)
      var longitude="-15.398542";    // center longitude (decimal degrees)
      var zoom="14";             // initial zoom (between 3 and 18)
    <\/script>`)
    postscribe('#full_div3', `<script src="https://www.vesselfinder.com/aismap.js"><\/script>`)
     
    
  }
};
</script>
