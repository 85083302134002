<template>
    <weather 
        api-key="bfca1b5c8ffd519c067f6d53cb25c639"
        title="Weather"
        latitude="28.142783"
        longitude="-15.417307"
        language="es"
        hide-header	
        units="si"
        text-color="white"
        bar-color="white">
    </weather>
</template>
 
<script>
import VueWeatherWidget from 'vue-weather-widget';
import 'vue-weather-widget/dist/css/vue-weather-widget.css';
 
export default {
    components: {
        'weather': VueWeatherWidget
    },
}
</script> 

<style lang="css" >
    div.fe_forecast {
        margin: 0 !important;
    }



</style>