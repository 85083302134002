<template>

<div>
    <div class="d-flex flex-column">
    <listabuque/>  
    </div>
  </div>  
</template>

<script>
import listabuque from "@/components/listabuque";

export default {
  name: "buquespuerto",
  
  components: {
    listabuque,
  }

 
}
</script>
<style lang="scss">
.titlePage{
  width: 100%;
    height: 53px;
    background-color: #01ca6e;
    color: white;
    text-align: center;
    padding: 11px;
    font-size: 25px;
}
.container.fluid {
    max-width: 100%;
    height: 100vh !important;
    padding: 7px;
}
</style>